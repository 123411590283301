<template>
  <div id="module-student-pay">
    <CoolLightBox :items="coolLightImages" :index="indexViewImg" @close="indexViewImg = null" />
    <div class="row" v-if="item">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="card card-info card-outline shadow">
          <div class="card-body box-profile pb-2">
            <div class="text-center mb-2" v-on:click="indexViewImg = 0">
              <!-- <img class="profile-user-img img-fluid img-circle" :src="fullPathFile(item.img)" alt="Student"> -->
              <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="120px" variant="default" />
            </div>
            <h3 class="profile-username text-center">{{ item.first_name }} {{ item.last_name }}</h3>
            <!-- <p class="text-muted text-center m-0">Software Engineer</p> -->
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 border-right">
                <div class="description-block">
                  <h5 class="description-header"><i class="fas fa-birthday-cake mr-1"></i><span>Ngày sinh</span>
                  </h5>
                  <span class="description-text">{{ formatDate(item.dob) }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="description-block">
                  <h5 class="description-header"><i class="fas fa-clipboard-check mr-1"></i><span>Ngày đăng ký</span>
                  </h5>
                  <span class="description-text">{{ formatDate(item.created_at) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-info card-outline shadow">
          <!-- <div class="card-header">
            <h3 class="card-title">Thông tin khác</h3>
          </div> -->
          <div class="card-body">

            <strong><i class="fas fa-user-tie mr-1"></i> Tên phụ huynh</strong>

            <p class="text-muted">{{ item.parent_name }}</p>

            <hr>

            <strong><i class="fas fa-phone mr-1"></i> Số điện thoại</strong>

            <p class="text-muted">{{ formatPhone(item.phone_number) }}</p>

            <hr>

            <strong><i class="fas fa-map-marker-alt mr-1"></i> Địa chỉ</strong>

            <p class="text-muted mb-0">{{ item.address }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-9 col-md-6 col-sm-12">
        <div class="card shadow">
          <div class="card-body p-0">
            <table class="table size-website">
              <thead>
                <tr>
                  <th>
                    <!-- <Order title="Tháng" column="dob" :callBack="orderBy" :orders="orders" /> -->
                    Tháng
                  </th>
                  <th>
                    <!-- <Order title="Sân vận động" column="first_name" :callBack="orderBy" :orders="orders" /> -->
                    Sân vận động
                  </th>
                  <th>
                    <!-- <Order title="Học phí" column="address" :callBack="orderBy" :orders="orders" /> -->
                    Học phí
                  </th>
                  <th>
                    <!-- <Order title="Lịch học" column="address" :callBack="orderBy" :orders="orders" /> -->
                    Lịch học
                  </th>
                  <th style="width: 79px;">
                    <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody v-if="items && items.items">
                <tr v-for="(item, index) in items.items" :key="index">
                  <td class="text-center">
                    <div class="text-nowrap text-bold">{{ item.month }}<span v-if="item.year">/{{ item.year }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="pl-1">{{ item.name }}</div>
                    <div class="pl-1"><i class="text-sm">{{ item.address }}</i></div>
                  </td>
                  <td class="text-right">
                    <div class="text-nowrap text-info">{{ formatNumber(item.money, true) }}</div>
                    <div :class="'text-nowrap text-sm' + (item.status == 0 ? ' text-bold text-danger' : '')"
                      v-if="item.money > 0">
                      {{ getTextOption(item.status, statusPayOptions) }}
                    </div>
                    <div class="text-nowrap text-sm ml-3" v-else>
                      Miễn phí
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-for="(cal, i) in item.calendars" :key="'calendars-' + i" class="text-nowrap">
                      {{ getTextOption(cal.day_in_week, dayInWeekOptions) }} - {{ cal.start_time }}
                    </div>
                    <div class="div-divider" v-if="item.learn_mores && item.learn_mores.length > 0">
                      <hr class="hr-divider" />
                      <span class="text-divider text-sm">Học bù</span>
                    </div>
                    <div v-for="(more, i) in item.learn_mores" :key="'learn_mores-' + i" class="text-nowrap">
                      {{ formatDate(more.date) }}
                      <span class="text-sm ml-1">
                        (<i v-if="more.status == 1">Đã học</i><i v-else>Không học</i>)
                      </span>
                    </div>
                    <!-- <div class="btn-group mt-1" v-if="item.calendars && item.calendars.length > 0">
                      <button class="btn btn-outline-info btn-xs" v-on:click="changeCalendar(item)">
                        <i class="fas fa-edit"></i><span class="ml-1">Thay đổi</span>
                      </button>
                    </div> -->
                  </td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i
                          class="fas fa-edit"></i></button>
                      <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                          class="far fa-trash-alt"></i></button>
                    </div>
                    <button class="btn btn-xs btn-outline-info btn-block mt-2" v-on:click="changeAttendance(item)">
                      <span>Học bù</span>
                    </button>
                  </td>
                </tr>
              </tbody>
              <Paging v-if="items" :items="items" column="7" :callBack="pagingBy" />
            </table>
            <table class="table size-mobile">
              <thead>
                <tr>
                  <th style="width: 79px;">
                    <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </th>
                  <th>Quá trình học</th>
                </tr>
              </thead>
              <tbody v-if="items && items.items">
                <tr v-for="(item, index) in items.items" :key="index">
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i
                          class="fas fa-edit"></i></button>
                      <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                          class="far fa-trash-alt"></i></button>
                    </div>
                    <button class="btn btn-xs btn-outline-info btn-block mt-2" v-on:click="changeAttendance(item)">
                      <span>Học bù</span>
                    </button>
                  </td>
                  <td>
                    <div class="text-nowrap text-bold">{{ item.month }}<span v-if="item.year">/{{ item.year }}</span>
                    </div>
                    <div><i class="fas fa-futbol mr-2"></i>{{ item.name }}</div>
                    <div><i class="text-sm">{{ item.address }}</i></div>
                    <div>
                      <i class="fas fa-dollar-sign text-sm mr-2"></i>
                      <span class="text-nowrap text-info">{{ formatNumber(item.money, true) }}</span>
                      <span :class="'text-nowrap text-sm ml-3' + (item.status == 0 ? ' text-bold text-danger' : '')"
                        v-if="item.money > 0">
                        ({{ getTextOption(item.status, statusPayOptions) }})
                      </span>
                      <span class="text-nowrap text-sm ml-3" v-else>
                        (Miễn phí)
                      </span>
                    </div>
                    <div class="div-divider">
                      <hr class="hr-divider" />
                      <span class="text-divider text-sm">Lịch học</span>
                    </div>
                    <div v-for="(cal, i) in item.calendars" :key="'calendars-' + i" class="text-nowrap">
                      {{ getTextOption(cal.day_in_week, dayInWeekOptions) }} - {{ cal.start_time }}
                    </div>
                    <div class="div-divider" v-if="item.learn_mores && item.learn_mores.length > 0">
                      <hr class="hr-divider" />
                      <span class="text-divider text-sm">Học bù</span>
                    </div>
                    <div v-for="(more, i) in item.learn_mores" :key="'learn_mores-' + i" class="text-nowrap">
                      {{ formatDate(more.date) }}
                      <span class="text-sm ml-1">
                        (<i v-if="more.status == 1">Đã học</i><i v-else>Không học</i>)
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <Paging v-if="items" :items="items" column="7" :callBack="pagingBy" />
            </table>
          </div>
        </div>
      </div>
    </div>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdStudent"
      :title="itemChange && itemChange.id ? 'Cập nhật' : 'Thêm mới'">
      <div class="row admin-page" v-if="itemChange">
        <div class="col-sm-12">
          <div class="form-group mb-0">
            <label class="control-label text-semibold label-valid">Sân vận động</label>
            <InputStadium ref="inputStadium" :province="itemChange.province_id" :district="itemChange.district_id"
              :stadium="itemChange.stadium_id.toString()" :callBack="setStadium" />
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Tháng</label>
            <div class="input-group">
              <input type="number" class="form-control" v-model="itemChange.month" id="month" placeholder="Tháng"
                autocomplete="off" min="1" max="12" style="width: 42%;">
              <input type="number" class="form-control" v-model="itemChange.year" id="year" placeholder="Năm"
                autocomplete="off" min="2000" max="3000" style="width: 58%;">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold">Học phí/tháng</label>
            <InputCurrency id="money" v-model="itemChange.money" placeholder="Học phí/tháng"></InputCurrency>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold">Tình trạng</label>
            <b-form-select id="status" v-model="itemChange.status" :options="statusPayOptions">
            </b-form-select>
          </div>
        </div>
        <div class="col-sm-12">
          <table class="table mb-1 mt-1 size-website">
            <thead>
              <tr>
                <th colspan="2">Lịch học</th>
                <th style="width: 50px; height: 48px;">
                  <button class="btn btn-success btn-sm" v-on:click="addCalendar()"
                    v-if="dayInWeekOptions.length > itemChange.calendar_data.length">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody v-if="itemChange.calendar_data">
              <tr v-for="(item, index) in itemChange.calendar_data" :key="index">
                <td>
                  <b-form-select id="day_in_week" v-model="item.day_in_week" :options="dayInWeekOptions">
                  </b-form-select>
                </td>
                <td>
                  <input type="time" class="form-control" v-model="item.start_time" id="start_time" autocomplete="off">
                </td>
                <td>
                  <div class="btn-group">
                    <button class="btn btn-default btn-sm" v-on:click="deleteCalendar(index)"><i
                        class="far fa-trash-alt"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table mb-1 mt-1 size-mobile">
            <thead>
              <tr>
                <th>Lịch học</th>
                <th style="width: 50px; height: 48px;">
                  <button class="btn btn-success btn-sm" v-on:click="addCalendar()"
                    v-if="dayInWeekOptions.length > itemChange.calendar_data.length">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody v-if="itemChange.calendar_data">
              <tr v-for="(item, index) in itemChange.calendar_data" :key="index">
                <td>
                  <b-form-select id="day_in_week" v-model="item.day_in_week" :options="dayInWeekOptions">
                  </b-form-select>
                  <input type="time" class="form-control mt-1" v-model="item.start_time" id="start_time"
                    autocomplete="off">
                </td>
                <td>
                  <div class="btn-group">
                    <button class="btn btn-default btn-sm" v-on:click="deleteCalendar(index)"><i
                        class="far fa-trash-alt"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold">Ghi chú</label>
            <textarea class="form-control" rows="3" placeholder="Ghi chú" v-model="itemChange.note"></textarea>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveItem(itemChange)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdStudent)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdCalendar" title="Thay đổi lịch học" size="lg">
      <div class="row admin-page" v-if="itemChangeCalendar">
        <div class="col-sm-6">
          <table class="table">
            <thead>
              <tr>
                <th colspan="2" style="height: 48px;">Lịch học cũ</th>
              </tr>
            </thead>
            <tbody v-if="itemChangeCalendar.calendars">
              <tr v-for="(item, index) in itemChangeCalendar.calendars" :key="index">
                <td>{{ getTextOption(item.day_in_week, dayInWeekOptions) }} </td>
                <td>{{ item.start_time }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6">
          <table class="table">
            <thead>
              <tr>
                <th colspan="2">Lịch học mới</th>
                <th style="width: 50px; height: 48px;">
                  <button class="btn btn-success btn-sm" v-on:click="addChangeCalendar()"
                    v-if="dayInWeekOptions.length > itemChangeCalendar.calendar_data.length">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody v-if="itemChangeCalendar.calendar_data">
              <tr v-for="(item, index) in itemChangeCalendar.calendar_data" :key="index">
                <td>
                  <b-form-select id="day_in_week" v-model="item.day_in_week" :options="dayInWeekOptions">
                  </b-form-select>
                </td>
                <td>
                  <input type="time" class="form-control" v-model="item.start_time" id="start_time" autocomplete="off">
                </td>
                <td>
                  <div class="btn-group">
                    <button class="btn btn-default btn-sm" v-on:click="deleteChangeCalendar(index)"><i
                        class="far fa-trash-alt"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveChangeCalendar(itemChangeCalendar)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdCalendar)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdAttendance" title="Lịch học bù">
      <div class="row admin-page" v-if="itemAttendance">
        <div class="col-md-5 col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold">Tháng</label>
            <div class="text-nowrap">
              {{ itemAttendance.month }}/{{ itemAttendance.year }}
            </div>
          </div>
        </div>
        <div class="col-md-7 col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold">Sân vận động</label>
            <div><i class="fas fa-futbol mr-2"></i>{{ itemAttendance.name }}</div>
            <div><i class="text-sm">{{ itemAttendance.address }}</i></div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group mb-0">
            <table class="table size-website mb-0">
              <thead>
                <tr>
                  <th colspan="2">Lịch học bù</th>
                  <th style="width: 50px; height: 48px;">
                    <button class="btn btn-success btn-sm" v-on:click="addAttendance()">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody v-if="listAttendances">
                <tr v-for="(item, index) in listAttendances" :key="index">
                  <td style="width: 50%;">
                    <input type="date" class="form-control" v-model="item.date" :id="'date-' + index"
                      placeholder="Ngày học" autocomplete="off">
                  </td>
                  <td>
                    <div class="icheck-info">
                      <input type="checkbox" :id="'attendance-' + index" v-model="item.attendance"
                        :disabled="!checkAttendance(item.date)">
                      <label :for="'attendance-' + index" class="w-100 m-0">
                        <span v-if="item.attendance">Có mặt</span>
                        <span v-else>Vắng mặt</span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-default btn-sm" v-on:click="deleteAttendance(index)"><i
                          class="far fa-trash-alt"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table size-mobile mb-0">
              <thead>
                <tr>
                  <th>Lịch học bù</th>
                  <th style="width: 50px; height: 48px;">
                    <button class="btn btn-success btn-sm" v-on:click="addAttendance()">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody v-if="listAttendances">
                <tr v-for="(item, index) in listAttendances" :key="index">
                  <td>
                    <input type="date" class="form-control mb-2" v-model="item.date" :id="'date-' + index"
                      placeholder="Ngày học" autocomplete="off">
                    <div class="icheck-info">
                      <input type="checkbox" :id="'attendance-' + index" v-model="item.attendance"
                        :disabled="!checkAttendance(item.date)">
                      <label :for="'attendance-' + index" class="w-100 m-0">
                        <span v-if="item.attendance">Có mặt</span>
                        <span v-else>Vắng mặt</span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-default btn-sm" v-on:click="deleteAttendance(index)"><i
                          class="far fa-trash-alt"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveAttendance(itemAttendance, listAttendances)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdAttendance)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiStudent from "../../services/apiStudent";
import ApiAttendance from "../../services/apiAttendance";
import { dayInWeekOptions, statusPayOptions } from "../../common/constants";
import { getAdminAuthen } from "../../common/storage";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  components: {
    CoolLightBox,
    Paging: () => import("../components/Paging.vue"),
    // Order: () => import("../components/Order.vue"),
    InputCurrency: () => import("../components/InputCurrency.vue"),
    InputStadium: () => import("../components/InputStadium.vue"),
  },
  data() {
    return {
      userLogin: null,
      coolLightImages: [],
      indexViewImg: null,
      item: null,
      statusPayOptions: statusPayOptions,
      dayInWeekOptions: dayInWeekOptions,
      items: null,
      paging: null,
      orders: [{ column: "month_year", type: "desc" }],
      modalIdStudent: "student-modal",
      modalIdCalendar: "calendar-modal",
      modalIdAttendance: "attendance-modal",
      itemChange: null,
      itemChangeCalendar: null,
      itemAttendance: null,
      listAttendances: []
    };
  },
  methods: {
    checkAttendance(dateChoose) {
      if (!dateChoose) return false;
      const date = this.formatDateYMD(dateChoose);
      const cur = this.formatDateYMD(new Date());
      return date == cur;
    },
    setStadium(provinceId, districtId, stadiumId) {
      console.log(provinceId, districtId, stadiumId);
      this.itemChange.stadium_id = stadiumId;
    },
    async getByStudent() {
      if (!this.$route.params) return;
      this.showLoading(true);
      const data = await ApiStudent.get(this.$route.params.studentId);
      this.showLoading(false);
      this.coolLightImages = [];
      if (data) {
        this.item = data;
        this.coolLightImages = [this.fullPathFile(data.img)];
      }
      this.initItems();
    },
    async initItems() {
      if (!this.item || !this.item.id) return;
      this.showLoading(true);
      const data = await ApiStudent.listPay(this.paging, this.orders, this.item.id);
      this.showLoading(false);
      if (data && data.items) this.items = data;
    },
    pagingBy(paging) {
      this.paging = paging;
      this.initItems();
    },
    orderBy(orders) {
      this.orders = orders;
      this.initItems();
    },
    makeItem(user) {
      const date = new Date();
      return { id: "", student_id: this.item.id, province_id: user ? user.province_id : "", district_id: user ? user.district_id : "", stadium_id: "", month: date.getMonth() + 1, year: date.getFullYear(), money: "0", number_of_day: "", status: "0", calendar_data: [], note: "" };
    },
    makeItemCalendar() {
      return { day_in_week: "1", start_time: "07:00" };
    },
    addItem() {
      this.itemChange = this.makeItem(this.userLogin);
      this.addCalendar();
      if (this.items && this.items.items && this.items.items.length > 0) {
        const item = this.items.items[0];
        this.itemChange.province_id = item.province_id;
        this.itemChange.district_id = item.district_id;
        this.itemChange.stadium_id = item.stadium_id;
        this.itemChange.money = item.money;
        this.itemChange.calendar_data = [];
        if (item.calendars) item.calendars.forEach(e => {
          this.itemChange.calendar_data.push(this.setObjectValue(this.makeItemCalendar(), e));
        });
      }
      this.showModal(this.modalIdStudent);
    },
    async updateItem(item) {
      this.itemChange = this.setObjectValue(this.makeItem(), item);
      this.itemChange.calendar_data = [];
      if (item.calendars) item.calendars.forEach(e => {
        this.itemChange.calendar_data.push(this.setObjectValue(this.makeItemCalendar(), e));
      });
      this.showModal(this.modalIdStudent);
    },
    checkValid(item) {
      console.log("item", item);
      if (item) return this.checkValidForm([
        { id: "stadium", value: item.stadium_id, message: 'Vui lòng nhập Sân vận động' },
        { id: "month", value: item.month, message: 'Vui lòng nhập Tháng' },
        { id: "year", value: item.year, message: 'Vui lòng nhập Năm' }
      ]);
      return false;
    },
    async saveItem(item) {
      item.number_of_day = item.calendar_data.length;
      if (this.checkValid(item)) {
        this.showLoading(true);
        const data = await ApiStudent.addOrUpdatePay(item);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.hideModal(this.modalIdStudent);
          this.initItems();
        });
      }
    },
    async deleteItem(item) {
      if (!item.id) return;
      this.confirmDelete("Quá trình học", `tháng ${item.month}/${item.year}`).then(async (result) => {
        if (!result) return;
        this.showLoading(true);
        const data = await ApiStudent.deletePay(item.id);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.initItems();
        });
      })
    },
    addCalendar() {
      if (this.itemChange) this.itemChange.calendar_data.push(this.makeItemCalendar());
    },
    deleteCalendar(index) {
      if (this.itemChange) this.itemChange.calendar_data.splice(index, 1);
    },
    async changeCalendar(item) {
      if (!item || !item.id) return;
      // this.showLoading(true);
      // const data = await ApiStudent.getCalendar(item.id);
      // this.showLoading(false);
      if (item.calendars) {
        this.itemChangeCalendar = { pay_id: item.id, calendars: item.calendars, calendar_data: [] }
        if (item.calendars.length > 0) item.calendars.forEach(e => {
          this.itemChangeCalendar.calendar_data.push(this.setObjectValue(this.makeItemCalendar(), e))
        });
        this.showModal(this.modalIdCalendar);
      }
    },
    addChangeCalendar() {
      if (this.itemChangeCalendar) this.itemChangeCalendar.calendar_data.push(this.makeItemCalendar());
    },
    deleteChangeCalendar(index) {
      if (this.itemChangeCalendar) this.itemChangeCalendar.calendar_data.splice(index, 1);
    },
    async saveChangeCalendar(item) {
      if (item) {
        this.showLoading(true);
        const data = await ApiStudent.changeCalendar(item);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.hideModal(this.modalIdCalendar);
          this.initItems();
        });
      }
    },
    async changeAttendance(item) {
      if (!item || !item.id) return;
      this.itemAttendance = item;
      // this.showLoading(true);
      // const data = await ApiAttendance.getAttendanceBy(item.id);
      // this.showLoading(false);
      this.listAttendances = [];
      if (item.learn_mores) this.listAttendances = item.learn_mores.map(e => {
        e.date = e.date.substring(0, 10);
        e.attendance = e.status == 1;
        return { date: e.date, attendance: e.attendance };
      });
      this.showModal(this.modalIdAttendance);
    },
    addAttendance() {
      this.listAttendances.push({ date: "", attendance: false });
    },
    deleteAttendance(index) {
      this.listAttendances.splice(index, 1);
    },
    checkValidAttendance(items) {
      console.log("items", items);
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const vaild = this.checkValidForm([
            { id: `date-${i}`, value: item.date, message: 'Vui lòng nhập Ngày học' }
          ]);
          if (!vaild) return false;
        }
        return true;
      }
      return false;
    },
    async saveAttendance(item, attendances) {
      if (this.checkValidAttendance(attendances)) {
        attendances.forEach(e => { e.status = e.attendance ? 1 : 0; });
        const itemPost = { stadium_id: item.stadium_id, student_id: item.student_id, pay_id: item.id, attendances: attendances }
        this.showLoading(true);
        const data = await ApiAttendance.addAttendance(itemPost);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.hideModal(this.modalIdAttendance);
          this.initItems();
        });
      }
    }
  },
  mounted() {
    this.userLogin = getAdminAuthen();
    this.getByStudent();
  }
};
</script>
